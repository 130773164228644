import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Page from "../components/Page"
import FancyHeader from "../components/blocks/FancyHeader"

const MdxPage = ({ data }) => {
  const { mdx } = data
  const { frontmatter, body } = mdx
  const { title, subTitle, seoTitle, seoDescription, path } = frontmatter
  
  // components={{
  //   // Map HTML element tag to React component
  //   h1: DesignSystem.H1,
  //   h2: DesignSystem.H2,
  //   h3: DesignSystem.H3,
  //   // Or define component inline
  //   p: props => <p {...props} style={{ color: "rebeccapurple" }} />,
  // }}

  return (
    <Page
      zeroTop
      seoPath={path}
      seoTitle={seoTitle}
      seoDescription={seoDescription}
    >
      <FancyHeader title={title} subTitle={subTitle} />
      <Container>
        <Row>
          <Col className="beautify">
            <MDXRenderer>{body}</MDXRenderer>
          </Col>
        </Row>
      </Container>
    </Page>
  )
}

export default MdxPage

export const pageQuery = graphql`
  query ($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        title
        seoTitle
        seoDescription
        subTitle
      }
    }
  }
`
